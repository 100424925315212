import Vue from "vue";
import VueRouter from "vue-router";
import Main from "@/views/main/main";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home",
    meta: {
      title: "首页"
    },
    component: () => import("@/views/homeNew/home")
  },
  {
    path: "/",
    name: "home",
    redirect: "/home",
    component: Main,
    children: [
      // {
      //   path: '/home',
      //   name: 'home',
      //   meta: {
      //     title: '首页'
      //   },
      //   component: () => import('@/views/home/home')
      // },
      {
        path: "/about",
        name: "about",
        meta: {
          title: "农业资讯"
        },
        component: () => import("@/views/agricuinfor/agricuinfor")
      },
      {
        path: "/informationdynamic",
        name: "informationdynamic",
        meta: {
          title: "信息动态"
        },
        component: () => import("@/views/informationdynamic/informationdynamic")
      },
      {
        path: "/raisingpigs",
        name: "raisingpigs",
        meta: {
          title: "养猪首页"
        },
        component: () => import("@/views/raisingpigs/raisingpigs")
      },
      {
        path: "/pigindustryinformation",
        name: "pigindustryinformation",
        meta: {
          title: "猪业资讯"
        },
        component: () =>
          import(
            "@/views/raisingpigs/pigindustryinformation/pigindustryinformation"
          )
      },
      {
        path: "/pigprico",
        name: "pigprico",
        meta: {
          title: "猪价行情"
        },
        component: () => import("@/views/raisingpigs/pigprico/pigprico")
      },
      {
        path: "/swinedisease",
        name: "swinedisease",
        meta: {
          title: "猪病大全"
        },
        component: () => import("@/views/raisingpigs/swinedisease/swinedisease")
      },
      {
        path: "/diseasecontrol",
        name: "diseasecontrol",
        meta: {
          title: "疫病防治"
        },
        component: () =>
          import("@/views/raisingpigs/diseasecontrol/diseasecontrol")
      },
      {
        path: "/pigtechnology",
        name: "pigtechnology",
        meta: {
          title: "养猪技术"
        },
        component: () =>
          import("@/views/raisingpigs/pigtechnology/pigtechnology")
      },
      {
        path: "/pigmanagement",
        name: "pigmanagement",
        meta: {
          title: "猪场管理"
        },
        component: () =>
          import("@/views/raisingpigs/pigmanagement/pigmanagement")
      },
      {
        path: "/pigformula",
        name: "pigformula",
        meta: {
          title: "饲料配方"
        },
        component: () => import("@/views/raisingpigs/pigformula/pigformula")
      },
      {
        path: "/zhishiku",
        name: "zhishiku",
        meta: {
          title: "农业知识库"
        },
        component: () => import("@/views/about/components/zhishiku")
      },
      {
        path: "/ruralFinance",
        name: "ruralFinance",
        meta: {
          title: "农村金融"
        },
        component: () => import("@/views/ruralFinance/ruralFinance")
      },
      {
        path: "/BenefitfarmersPolicy",
        name: "BenefitfarmersPolicy",
        meta: {
          title: "惠农政策"
        },
        component: () =>
          import("@/views/BenefitfarmersPolicy/BenefitfarmersPolicy")
      },
      {
        path: "/knowledgeBase",
        name: "knowledgeBase",
        meta: {
          title: "农业知识库"
        },
        component: () => import("@/views/knowledgeBase/knowledgeBase")
      },
      {
        path: "/domesticDetails",
        name: "domesticDetails",
        meta: {
          title: "四级页"
        },
        component: () => import("@/views/domesticDetails/domesticDetails")
      },
      {
        path: "/agricuunforThree",
        name: "agricuunforThree",
        meta: {
          title: "农业资讯三级页"
        },
        component: () =>
          import("@/views/agricuinfor/components/agricuunforThree")
      },
      {
        path: "/emergdation",
        name: "emergdation",
        meta: {
          title: "新兴推荐"
        },
        component: () =>
          import("@/views/agricuinfor/components/agricuunforThree")
      },
      {
        path: "/emergreview",
        name: "emergreview",
        meta: {
          title: "热门话题"
        },
        component: () =>
          import("@/views/agricuinfor/components/agricuunforThree")
      },
      {
        path: "/commonlayout",
        name: "commonlayout",
        meta: {
          title: "共用页面"
        },
        component: () => import("@/views/common/commonLayout")
      },
      {
        path: "/childlayout",
        name: "childcommonlayout",
        meta: {
          title: "二级共用页面"
        },
        component: () => import("@/views/common/childCommonLayout")
      },
      {
        path: "/right",
        name: "right",
        meta: {
          title: "右边"
        },
        component: () => import("@/views/homeNew/components/right.vue")
      },
      {
        path: "/left",
        name: "left",
        meta: {
          title: "左边"
        },
        component: () => import("@/views/homeNew/components/left.vue")
      },
      {
        path: "/AgriculturalData",
        name: "AgriculturalData",
        meta: {
          title: "农业大数据"
        },
        component: () => import("@/views/AgriculturalData/AgriculturalData.vue")
      },
      {
        path: "/search",
        name: "search",
        meta: {
          title: "搜索"
        },
        component: () => import("@/views/search/search.vue")
      },
      {
        path: "/piginfothree",
        name: "piginfothree",
        meta: {
          title: "猪业资讯"
        },
        component: () =>
          import("@/views/agricuinfor/components/piginfothree.vue")
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录"
    },
    component: () => import("@/views/login/login")
  },
  {
    path: "/portal",
    name: "portal",
    meta: {
      title: "首页"
    },
    component: () => import("@/views/homeNew/portal")
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 回滚顶部
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
