import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSeamlessScroll from 'vue-seamless-scroll'
import TextHighlight from 'vue-text-highlight';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.component('text-highlight', TextHighlight);
Vue.use(VueSeamlessScroll)
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
