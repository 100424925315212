<template>
  <div>
    <Header v-show="paths!='/AgriculturalData'"></Header>
    <Nav v-show="paths!='/AgriculturalData'"></Nav>
    <div class="main-container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from '../components/top'
import Nav from '../components/prico'
export default {
  name: 'main',
  components: {
    Header,
    Nav,
  },
  data () {
    return{
      paths:''
    }
  },
  watch: {
    $route: {
      handler(to,from) {
        this.paths=to.path
        console.log(this.paths)
      },
      deep:true,
      immediate: true
    }
  },
}
</script>

<style scoped>
  .main-container {
  }
</style>
