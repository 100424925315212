<template>
  <div class="headbg">
    <div class="head flex">
      <div class="flex">
        <div class="lineLeft" @click="home()">首页</div>
        <div class="service" @click="isshow($event)">服务 <i class="arrow" :class="{'up': show}"></i></div>
      </div>
      <div class="flex">
        <div v-if="name==''">
          <div class="flex aitem" :class="shows?'expend-search':''">
             <select v-model="tab" name="fruit" v-if="show1==1" @mouseenter="overs()" @mouseleave="out()">
                <option value="1">标题</option>
                <option value="2">内容</option>
              </select>
            <input type="text" class="search" v-model="invalue" ref="inputVal" @click="focus1()" @focus="focus1()" @blur="btns()" @keyup.enter="selvers()" v-show="paths!='/search'">
            <img @click="selvers()" @mouseenter="overs()" @mouseleave="out()" src="@/assets/img/sousuo.png" class="img" v-show="paths!='/search'">
            <!-- <div class="login" @click="login">登录</div> -->
            <!-- <div class="regsed">免费注册 <i class="arrow"></i></div> -->
          </div>
        </div>
        <!-- <div v-if="name!=''">
          <div class="flex aitem">
            <input type="text" class="search">
            <img src="@/assets/img/sousuo.png" class="img">
            <div class="login">{{name}}</div>
           <div class="regsed" @click="tchu()">退出登录</div>
          </div>
        </div> -->
        <div @click="isshows()" class="btn" :class="shows?'expend-btn':''">
          <button class="nav-mobile-button" :class="shows?'show':''">
            <span class="sr-only"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <div class="close" :class="shows?'':'show'">
            ×
          </div>
        </div>
      </div>

    </div>
    <div class="Unfold-wrap" v-if="show">
      <i class="maskbg" @click="show = false"></i>
      <div class="serviceUnfold Unfold flex">
        <div v-for="(item, idx) in anchorList" :key="idx" >
          <!-- <a :href="idx==3?'/#/AgriculturalData':item.path" target="_blank">{{item.appName}}</a> -->
          <a :href="item.path" target="_blank">{{item.appName}}</a>
          <!-- <p @click="login1()">登录</p> -->
          <!-- <p>注册</p> -->
        </div>
        <!-- <div> -->
          <!-- <h1>农业大数据</h1> -->
          <!-- <p @click="login2()">登录</p> -->
          <!-- <p>注册</p> -->
        <!-- </div> -->
        <!-- <div> -->
          <!-- <h1>综合资源</h1> -->
          <!-- <p @click="login3()">登录</p> -->
          <!-- <p>注册</p> -->
        <!-- </div> -->
        <!-- <div> -->
          <!-- <h1>大数据采集</h1> -->
          <!-- <p @click="login4()">登录</p> -->
          <!-- <p>注册</p> -->
        <!-- </div> -->
        <!-- <div> -->
          <!-- <h1>质量追溯</h1> -->
          <!-- <p @click="login5()">查询</p> -->
        <!-- </div> -->
      </div>
    </div>

    <div class="Unfold-wrap" v-if="shows">
      <i class="maskbg" @click="shows = false"></i>
      <div class="Unfold catalog flex">
        <div v-for="(item,idx) in columList" :key="idx" v-show="item.isHome==0">
          <h1 @click="son(item)" class="pointer" >{{item.columnName}}</h1>
        </div>
      </div>
    </div>

  </div>
</template>

<script src="https://cdn.staticfile.org/vue/2.2.2/vue.min.js"></script>
<script>
  import { getQuotes } from '@/api/home2.js'
  import { getAnchorList, getqueryInfor } from '@/api/home1.js'
  import { getWebTitle } from '@/api/home1'
  export default {
    name: "top",
    data() {
      return {
        anchorList:'',
        show:false,
        shows:false,
        columList: '',
        data:[],
        name:'',
        pwd:'',
        columid:'',
        pathname:'',
        paths:'',
        show1:0,
        invalue:'',
        tab:1,
        time:'',
        over:0,
        paths:''
      }
    },
    methods: {
      isshow(e){
        this.shows = false
        this.show = !this.show
      },
      isshows(){
        this.shows = !this.shows
        this.show = false
      },
      son(item){
        this.shows=false
        if (item.columnName=='惠农政策'){
          this.$router.push(item.accessPath)
        }else if(item.accessPath.slice(0,1)=='/'){
         this.$router.push({
            path: item.accessPath,
            query: {
              columid: item.id,
              columnNameId:item.id,
            }
          })
        }else{
          window.location.href=item.accessPath
        }

      },
      login () {
        this.$router.replace('/login')
      },
      login1 () {
        window.location.href='http://10.11.211.62:8082/#/login'
      },
      login2 () {
        this.$router.replace('')
      },
      login3 () {
        window.location.href='http://10.11.211.62:8083/#/login'
      },
      login4 () {
        this.$router.replace('')
      },
      login5 () {
        window.location.href='http://qsst.gdagri.gov.cn/nytsy/portal/index.jsp'
      },
      getcolum () {
        getQuotes({
          parentId:0
        }).then((res) => {
          this.columList=res.data
          // for(let i=0;i<res.data.length;i++){
          //   getQuotes({
          //     parentId:res.data[i].id
          //   }).then((res) => {
          //     this.data.push(res.data)
          //   })
          // }
        })
      },
      home () {
        this.$router.push('/home')
      },
       setCookie (cname, cpwd, exdays) {
        var exdate = new Date() // 获取时间
        exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays)// 保存的天数
        // 字符串拼接cookie
        window.document.cookie = 'username' + '=' + cname + ';path=/;expires=' + exdate.toGMTString()
        window.document.cookie = 'passwords' + '=' + cpwd + ';path=/;expires=' + exdate.toGMTString()
      },
      getCookie () {
        if (document.cookie.length > 0) {
          var arr = document.cookie.split('; ')// 这里显示的格式需要切割一下自己可输出看下
          for (var i = 0; i < arr.length; i++) {
            var arr2 = arr[i].split('=')// 再次切割
            // 判断查找相对应的值
            if (arr2[0] === 'username') {
              this.name = arr2[1]// 保存到保存数据的地方
            } else if (arr2[0] === 'passwords') {
              this.pwd = arr2[1]
            }
          }
        }
      },
      clearCookie () {
        this.setCookie('', '', -1)// 修改2值都为空，天数为负1天就好了
      },
      tchu(){
        this.clearCookie()
        location.reload();
      },
      getanchorList () {
        getAnchorList({
          size: 5
        }).then((res) => {
          this.anchorList = res.data.records
          if(window.location.href.indexOf("?CAS_VALIDATION")!=-1){
            for(let i in this.anchorList){
              if(window.location.href.indexOf("?CAS_VALIDATION")){
                 this.anchorList[i].path = this.anchorList[i].path + "?CAS_VALIDATION"
              }
            }
          }


          this.anchorList.sort(function(a,b){
            return a.sort - b.sort
          })
        })
    },
    focus1(){
      this.over=1
      this.show1=1
      this.$refs.inputVal.className='search1'
    },
    btns(){
      if(this.over==1){
        this.show1=0
        this.$refs.inputVal.className='search'
      }
    },
    overs(){
      this.over=0
    },
    out(){
      this.over=0
    },
    selvers(){
      if(this.tab==1){
        getqueryInfor({
          title:this.invalue
        }).then((res) => {
          this.$router.push({
              path: '/search',
            query: {
                searchname:this.invalue,
                tab:this.tab
            }
          })
        })
      }else if(this.tab==2){
         getqueryInfor({
            content:this.invalue
          }).then((res) => {
            this.$router.push({
              path: '/search',
              query: {
                  searchname:this.invalue,
                  tab:this.tab
              }
            })
          })
        }
      },
    //获取网站名称
    getWebTitle2() {
      getWebTitle().then((res) => {
        if (!res.data.records[0].status) {
          document.title = res.data.records[0].websiteName
        }else{
          document.title = ''
        }

      })
    }
    },
    mounted() {
      // 不注释下面的方法，该方法获取网站名称
      this.getWebTitle2()
      this.getCookie()
      this.getcolum()
      this.getanchorList()
    },
    watch: {
      $route: {
        handler(to,from) {
          this.paths=to.path
        },
        deep:true,
        immediate: true
      }
    },
  }
</script>

<style scoped>
  .headbg{
    background-color: #333;
    width: 100%;
  }
  .head{
    width: 90%;
    margin: 0 auto;
    color: #fff;
    justify-content: space-between;
    padding: 0 100px;
    align-items: center;
    font-size: 16px;
    height: 60px;
    position: relative;
    max-width: 1300px;
    z-index: 30;
  }
  .nav-mobile-button {
    position: relative;
    padding: 0px 13px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    background: #333;
    /* top: 18px; */
    cursor: pointer;
  }
  .nav-mobile-button .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .nav-mobile-button .icon-bar {
    display: block;
    margin-top: 4px;
    width: 22px;
    height: 1px;
    background: #999999;
    border-radius: 1px;
  }
  .btn{
    position: relative;
    padding-left: 2px;
  }
  .btn::before{
    content: "";
    width: 1px;
    height: 50px;
    margin: 0 10px;
    position: absolute;
    border-left: 1px solid #717171;
    /* top: 6px; */
    top: -10px;
    left: -10px;
  }
  .lineLeft{
    position: relative;
    cursor: pointer;
  }
  .lineLeft::after{
    content: "";
    width: 1px;
    height: 49px;
    margin: 0 10px;
    position: absolute;
    border-left: 1px solid #717171;
    top: -12px;
  }
  .service{
    margin-left: 20px;
    cursor: pointer;
  }
  .head .arrow {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-image: url("~@/assets/img/icon_nav_arrow.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 12px auto;
    vertical-align: middle;
  }
  .head .arrow.up {
    transform: rotate(180deg);
    transition: .5s;
  }
  .aitem{
    align-items: center;
    position: relative;
  }
  .search{
    border: 1px solid #717171;
    background-color: #333;
    border-radius: 20px;
    width: 180px;
    height: 30px;
    color: #fff;
    padding-left:10px;
    padding-right: 40px;
    margin-right: 30px;
    font-size: 14px;
  }
   .search1{
    border: 1px solid #717171;
    background-color: #333;
    border-radius: 20px;
    width: 600px;
    height: 30px;
    color: #fff;
    padding-left:10px;
    padding-right: 40px;
    margin-right: 30px;
    font-size: 14px;
  }
  .img{
    width: 16px;
    height: 16px;
    position: relative;
    left: -60px;
    cursor: pointer;
  }
  .login{
    margin: 0 20px;
    background-color: #51d5c3;
    width: 80px;
    height: 56px;
    top: 5px;
    position: relative;
    right: 20px;
    line-height: 46px;
    cursor: pointer;
    z-index: 2;
    text-align: center;
  }
  .regsed{
    margin-right: 30px;
    cursor: pointer;
  }
  .Unfold-wrap{
    position: absolute;
    background: rgba(0,0,0,.7);
    width: 100%;
    top: 46px;
    z-index: 15;
  }
  .Unfold{
    width: 90%;
    margin: 0 auto;
    color: #fff;
    position: relative;
    max-width: 1300px;

  }
  .serviceUnfold{
    left: 0;
    z-index: 20;
  }
  .Unfold>div{
    margin-top: 40px;
    text-align: center;
    margin-bottom: 40px;
  }
  .serviceUnfold>div:first-child{
    margin-right: 40px;
  }
  .serviceUnfold>div+div{
    margin-right: 40px;
  }
  .serviceUnfold p{
    cursor: pointer;
  }
  .Unfold a{
    font-size:16px ;
    margin-bottom: 20px;
    font-weight: bold;
    display: inline-block;
    color: #fff;
  }
  .Unfold p{
    margin-bottom: 20px;
  }
  .catalog{
    right: 0;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 20;
  }
  .catalog>div:first-child{
    margin-left: 40px;
  }
  .catalog>div+div{
    margin-left: 40px;
  }
  .show{
    display: none;
  }
  .close{
    font-size: 40px;
    margin-top: 5px;
    color: red;
    width: 50px;
    text-align: center;
    line-height: 46px;
  }
  .maskbg{
    position: fixed;
    left: 0;
    right: 0;
    top: 46px;
    bottom: 0;
    z-index: 10;
  }
  .pointer{
    cursor: pointer;
  }
  .expend-btn:before{
    top: 2px !important;
  }
  .expend-search{
    margin-top: 15px;
  }
  select{
    margin-right: 10px;
    background-color: #333;
    color: #fff;
  }
</style>
