import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    columnData: {}
  },
  mutations: {
    setColumnData (state, payload) {
      state.columnData = payload
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    // ...
    getColumnData: (state) => {
      if (JSON.stringify(state.columnData) === '{}') {
        return JSON.parse(window.sessionStorage.getItem('_digitalagri_comprehensive_column_'))
      } else {
        return state.columnData
      }
    }
  }
})
