import { getRequest, postJsonRequest } from "@/api/axios";

// 获取functions组件图片相关信息
export const getAnchorList = params => {
  return getRequest("/blade-integrate/openapi/integrateapp/list", params);
};

// 获取banner组件图片相关信息
export const getBanner = params => {
  return getRequest(
    "/blade-integrate/openapi/integratewebsiteconfig/getcurrentwebsiteInfo",
    params
  );
};

// 获取用户登录信息
export const getNavigationList = params => {
  return getRequest(
    "/blade-integrate/openapi/integratecolumninfo/list",
    params
  );
};

// 获取农业咨询下的栏目信息
export const getInforId = params => {
  return getRequest(
    "/blade-integrate/openapi/integratecolumninfo/column-list",
    params
  );
};

// 数据栏目id拿数据
export const getLeftList = params => {
  return getRequest(
    "/blade-integrate/openapi/integratelabelinfo/labelListByColumnId",
    params
  );
};

// 数据栏目id拿详细数据
export const getContentList = params => {
  return getRequest(
    "/blade-integrate/openapi/integrateinformation/list",
    params
  );
};

// 获取农村金融栏目列表目录
export const getRuralFinanceList = params => {
  return getRequest(
    "/blade-integrate/openapi/integratelabelinfo/labelListByColumnId",
    params
  );
};

// 获取栏目列表目录的内容
export const getPolicyContent = params => {
  return getRequest(
    "/blade-integrate/openapi/integrateinformation/list",
    params
  );
};

// 获取农业资讯详情
export const getDetail = params => {
  return getRequest(
    "/blade-integrate/openapi/integrateinformation/getDetail",
    params
  );
};

// 获取热点资讯和实时资讯
export const getNews = params => {
  return postJsonRequest(
    "/blade-integrate/openapi/integrateinformation/listByType",
    params
  );
};

export const getdetail = params => {
  return getRequest(
    "/blade-integrate/openapi/integratecolumninfo/detail",
    params
  );
};

// 获取网站名称
export const getWebTitle = params => {
  return getRequest(
    "/blade-integrate/openapi/integratewebsiteconfig/list",
    params
  );
};

export const getqueryInfor = params => {
  return getRequest(
    "/blade-integrate/openapi/integrateinformation/queryInformationInfo",
    params
  );
};
