import { getRequest } from '@/api/axios'

// 获取农村金融栏目列表目录
export const getRuralFinanceList = (params) => {
  return getRequest('/blade-integrate/openapi/integratelabelinfo/labelListByColumnId', params)
}

// 获取金融机构栏目列表目录的内容
export const getOrganizationContent = (params) => {
  return getRequest('/blade-integrate/openapi/integratebankinfo/list', params)
}

// 获取农村电商信息
export const getbusinessList = (params) => {
  return getRequest('/blade-integrate/openapi/integrateecommerceinfo/list', params)
}

// 获取猪价行情栏目
export const getQuotes = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/column-list', params)
}

// 获取猪价行情信息
export const getQuotesList = (params) => {
  return getRequest('/blade-integrate/openapi/integrateinformation/list', params)
}

//获取所有类型的价格
export const getprico = (params) => {
  return getRequest('/blade-integrate/getIntegrateProductNationalPrice', params)
}

export const getdetail = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/detail', params)
}

export const getQuotesview = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/list', params)
}

export const getpricotype = (params) => {
  return getRequest('/blade-integrate/openapi/integrateprice/priceListByType', params)
}

export const getxinxingPrice = (params) => {
  return getRequest('/blade-integrate/openapi/integrateproductprice/getxinxingPrice', params)
}