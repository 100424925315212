<template>
  <div class="flex pricobg">
    <div @click="sonhome()" style="cursor: pointer;">
      <img src="../../assets/img/logonews.png" alt="" class="site-logo">
    </div>
    <div class="flex prico">
        <div class="pricos">
          <p>广东猪价</p>
           <div class="flex pigprico" :title="'日期：'+data.toDayTime">
              <div :class="data.trend=='up'?'red':'green'" >{{data.price=='-1'?'--':data.price}}<span>元/公斤</span></div>
              <div v-if="data.trend=='same'" class="lineSize"><img src="../../assets/img/line.png" alt=""></div>
              <div v-if="data.trend=='up'"><img src="../../assets/img/rise.png" alt=""></div>
              <div v-if="data.trend=='down'"><img src="../../assets/img/decline.png" alt=""></div>
              <div :class="data.trend=='up'?'red':'green'">
                  <p v-if="data.trend=='same'"></p>
                  <p v-if="data.trend=='up'">+{{data.diffYesterday.toFixed(2)}}</p>
                  <p v-if="data.trend=='down'">{{data.diffYesterday.toFixed(2)}}</p>
                  <p v-if="data.trend=='same'"></p>
                  <p v-if="data.trend=='up'">↑{{(((data.diffYesterday/(data.price-data.diffYesterday)).toFixed(3))*100).toFixed(1)}}%</p>
                  <p v-if="data.trend=='down'">↓{{-(((data.diffYesterday/(data.price-data.diffYesterday)).toFixed(3))*100).toFixed(1)}}%</p>
              </div>
          </div>
        </div>
        <div class="pricos">
          <p>{{constCommon.shortName}}猪价</p>
          <div class="flex pigprico"  :title="'日期：'+data1.toDayTime">
              <div :class="data1.trend=='up'?'red':'green'">{{data1.price=='-1'?'--':data1.price}}<span>元/公斤</span></div>
              <div v-if="data1.trend=='same'" class="lineSize"><img src="../../assets/img/line.png" alt=""></div>
              <div v-if="data1.trend=='up'"><img src="../../assets/img/rise.png" alt=""></div>
              <div v-if="data1.trend=='down'"><img src="../../assets/img/decline.png" alt=""></div>
              <div :class="data1.trend=='up'?'red':'green'">
                  <p v-if="data1.trend=='same'"></p>
                  <p v-if="data1.trend=='up'">+{{data1.diffYesterday.toFixed(2)}}</p>
                  <p v-if="data1.trend=='down'">{{data1.diffYesterday.toFixed(2)}}</p>
                  <p v-if="data1.trend=='same'"></p>
                  <p v-if="data1.trend=='up'">↑{{(((data1.diffYesterday/(data1.price-data1.diffYesterday)).toFixed(3))*100).toFixed(1)}}%</p>
                  <p v-if="data1.trend=='down'">↓{{-(((data1.diffYesterday/(data1.price-data1.diffYesterday)).toFixed(3))*100).toFixed(1)}}%</p>
              </div>
          </div>
        </div>
        <div class="pricos">
          <p>新牧网猪价指数</p>
           <div class="flex pigprico" :title="'日期：'+data2.toDayTime">
              <div :class="data2.trend=='up'?'red':'green'">{{data2.price=='-1'?'--':data2.price|FormatFixed}}</div>
              <div v-if="data2.trend=='same'" class="lineSize"><img src="../../assets/img/line.png" alt=""></div>
              <div v-if="data2.trend=='up'"><img src="../../assets/img/rise.png" alt=""></div>
              <div v-if="data2.trend=='down'"><img src="../../assets/img/decline.png" alt=""></div>
              <div :class="data2.trend=='up'?'red':'green'">
                  <p v-if="data2.trend=='same'"></p>
                  <p v-if="data2.trend=='up'">+{{data2.diffYesterday.toFixed(2)}}</p>
                  <p v-if="data2.trend=='down'">{{data2.diffYesterday.toFixed(2)}}</p>
                  <p v-if="data2.trend=='same'"></p>
                  <p v-if="data2.trend=='up'">↑{{(((data2.diffYesterday/(data2.price-data2.diffYesterday)).toFixed(4))*100).toFixed(2)}}%</p>
                  <p v-if="data2.trend=='down'">↓{{-(((data2.diffYesterday/(data2.price-data2.diffYesterday)).toFixed(4))*100).toFixed(2)}}%</p>
              </div>
          </div>
        </div>
        <div class="time">
            <div>{{day}}</div>
            <p>{{year}}年{{month}}月</p>
        </div>
    </div>
  </div>
</template>

<script>
  import { getprico } from '@/api/prico'
  import {constCommon} from '@/consts/common'
  export default {
    name: 'prico',
    data () {
      return {
        constCommon:constCommon,
        year: '',
        month: '',
        day: '',
        data:{},
        data1:'',
        data2:''
      }
    },
    created () {
      this.getDate()
      this.getpricos()
    },
    methods: {
      getDate () {
        const date = new Date()
        this.year = date.getFullYear()    //获取完整的年份(4位,1970-????)
        this.month = date.getMonth() + 1       //获取当前月份(0-11,0代表1月)
        this.day = date.getDate()
      },
      getpricos(){
        getprico({}).then((res) => {
          this.data=res.data.price[0]
          this.data1=res.data.price[1]
          this.data2=res.data.price[2]
        })
      },
      sonhome(){
        this.$router.push('/home')
      }
    },
    filters: {
      FormatFixed(val) {
        let num = parseFloat(val)
        if (num > 0) {
          return num.toFixed(2)
        } else {
          return '--'
        }
      }
    }
  }
</script>

<style scoped>
  .pricobg{
    margin: 0 auto;
    justify-content: space-between;
    margin-top:34px;
    font-size: 16px;
    margin-bottom: 24px;
    width: 100%;
    max-width: 1300px;
  }
  .prico>div+div{
    border-left: 1px solid #45dfca;
    margin-left: 20px;
    padding-left: 20px;
  }
  .pricos{
    text-align: left;
  }
  .pricos p{
      font-size: 18px;
      font-weight: bold;
  }
  .green{
    color: #4a9e28;
  }
  .red{
      color: #9e2828;
  }
  .pigprico{
    margin-top: 10px;
  }
  .pigprico>div:nth-child(1){
    font-size: 30px;
  }
  .pigprico>div:nth-child(2){
      margin: 0 5px;
  }
  .time div{
      width: 50px;
      height: 50px;
      background-color: #51d5c3;
      color: #fff;
      font-size: 30px;
      text-align: center;
      line-height: 50px;
      border-radius: 5px;
      margin: 0 auto;
      margin-bottom: 10px;
  }
  .time p{
      text-align: center;
      color: #a3a3a3;
      white-space: nowrap;
  }
  .lineSize{
    height: 7px;
    width: 45px;
    padding-top: 7px;
  }
    .lineSize img{
      width: 100%;
      height: 100%;
  }
  .pricos span{
    font-size: 12px;
    color: #a3a3a3;
    margin-left: 7px;
  }
  .site-logo{
    width: 410px;
    margin-top: 5px;
  }
</style>
