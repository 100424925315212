import axios from 'axios'
import Vue from 'vue'

// 超时设定
axios.defaults.timeout = 60000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.interceptors.request.use(config => {
  return config
}, err => {
  Vue.prototype.$Message.error('请求超时')
  return Promise.resolve(err)
})

// eslint-disable-next-line no-irregular-whitespace
const apiPath = '/api'

// http response 拦截器
axios.interceptors.response.use(response => {
  const data = response.data
  return data
}, (err) => {
  // 返回状态码不为200时候的错误处理
  Vue.prototype.$Message.error(err.toString())
  return Promise.resolve(err)
})

export const getRequest = (url, params) => {
  return axios({
    method: 'get',
    url: `${apiPath}${url}`,
    params: params,
    headers: {
    }
  })
}

export const postRequest = (url, params, header) => {
  const globalHeader = {}
  const headers = Object.assign(globalHeader, header)
  return axios({
    method: 'post',
    url: `${apiPath}${url}`,
    data: params,
    transformRequest: [function (data) {
      let ret = ''
      for (const it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      return ret
    }],
    headers: headers
  })
}

export const postJsonRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${apiPath}${url}`,
    data: params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
